.calendar {
	box-sizing: border-box;
	display: grid;
	padding: 20px;
	align-content: start;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

	.calendar__period-picker {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 50px;
		font-weight: 300;
		justify-content: space-between;
	}

		.calendar__period {
			display: inline-block;
			text-align: center;
		}

			.calendar__period--current {
				flex: 0 0 auto;
				min-width: 250px;
			}

			.calendar__period--prev,
			.calendar__period--next {
				flex: 0 0 60px;
				color: #999;
			}

			.calendar__period--prev {
				margin-right: 20px;
			}

			.calendar__period--next {
				margin-left: 20px;
			}

				.calendar__period svg {
					vertical-align: -8px;
				}

	.calendar__month {
		margin-top: 10px;
		grid-row: 2;
		grid-column: 1;
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 10px;
	}

		.calendar__month--hidden {
			opacity: 0;
			transform: scale(0.7);
			pointer-events: none;
		}

		.calendar__month th,
		.calendar__month td {
			padding: 5px;
			width: 50px;
			vertical-align: baseline;
			text-align: center;
		}

		.calendar__month th {
			font-weight: 400;
		}

			th.calendar__week-number {
				font-size: 20px;
				width: 35px;
				color: #999;
			}

		td.calendar__day {
			color: #fff;
		}

			td.calendar__day--other-month {
				color: #666;
			}

			td.calendar__day--today {
				background: #eee;
				color: #000;
			}

	.calendar__year {
		margin-top: 10px;
		grid-row: 2;
		grid-column: 1;
		justify-self: center;
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 10px;
	}

		.calendar__year--hidden {
			opacity: 0;
			transform: scale(1.4);
			pointer-events: none;
		}

		.calendar__year td {
			width: 120px;
			vertical-align: baseline;
			text-align-last: center;
			font-weight: 300;
		}

			td.calendar__year-month--current {
				background: #eee;
				color: #000;
			}

			.calendar__year-month button {
				padding: 35px 10px;
				width: 100%;
			}

	.calendar__month,
	.calendar__year {
		transition:
			transform 250ms ease-in-out,
			opacity 250ms ease-in-out;
	}
