.weather {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	/* padding: 20px; */
	height: 100%;
}

	.weather__current {
		display: flex;
		padding-top: 20px;
		flex: 0 0 auto;
		justify-content: center;
		align-content: flex-end;
	}

		.weather__icon {
			margin-right: 20px;
			align-self: center;
		}

			.weather__icon svg {
				display: block;
			}

		.weather__temperature {
			font-family: 'roboto condensed', sans-serif;
			font-size: 155px;
			line-height: 126px;
			font-weight: 300;
			color: #fff;
		}

			.weather__temperature-unit {
				font-size: 35px;
				line-height: 41px;
			}

		.weather__details {
			margin-left: 20px;
			font-family: 'roboto condensed', sans-serif;
			font-weight: 300;
			line-height: 42px;
		}

			.weather__details svg {
				margin-right: 5px;
				vertical-align: -6px;
			}

	.weather__condition {
		margin: 20px;
		text-align: center;
	}

	.weather__sun {
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
	}

		.weather__sun svg {
			margin-left: 40px;
			margin-right: 10px;
			vertical-align: -5px;
		}

			.weather__sun svg:first-child {
				margin-left: 0;
			}

	.weather__forecast {
		display: flex;
		flex: 1 1 auto;
	}

		.weather__forecast .forecast {
			flex: 1 1 auto;
		}

.weather-summary__detail {
	margin-left: 20px;
}

	.weather-summary__detail:first-child {
		margin-left: 0;
	}

	.weather-summary__detail svg {
		margin-right: 5px;
	}

	.weather-summary__unit {
		font-size: 24px;
	}
