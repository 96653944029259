* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'roboto', sans-serif;
  font-size: 35px;
  background: #000;
  color: #ddd;
}

#root {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}

button {
  font: inherit;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
}
