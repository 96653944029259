.transportation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

  .transportation--trains {
    grid-template-rows: auto 1fr auto 0;
  }

  .transportation--buses {
    grid-template-rows: auto 0 auto 1fr;
  }

  .transportation__header {
    padding: 10px 20px;
    flex: none;
    font-size: 40px;
    line-height: 42px;
    font-weight: 400;
    text-transform: uppercase;
  }

    .transportation__header:not(:first-child) {
      border-top: 1px solid #666;
    }

    .transportation__header svg {
      margin-right: 10px;
      vertical-align: -10px;
    }

  .transportation__list {
    flex: 1 1 100%;
    list-style-type: none;
    overflow-x: hidden;
    overflow-y: auto;
    transition: flex-basis 350ms ease-in-out;
  }

    .transportation--trains .transportation__list--buses,
    .transportation--buses .transportation__list--trains {
      flex-basis: 0;
    }

    .transportation__item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }

      .transportation__time {
        display: inline-block;
        flex: 0 0 88px;
        margin-top: -10px;
        margin-bottom: -10px;
        font-family: 'roboto condensed', sans-serif;
        line-height: 40px;
        color: #fff;
      }

        .transportation__item--severity-minor .transportation__time {
          color: #fff176;
        }

        .transportation__item--severity-major .transportation__time {
          color: #ef5350;
        }

        .transportation__item--severity-passed .transportation__time {
          color: #666;
        }

        .transportation__scheduled-time {
          display: block;
        }

          .transportation__time--deviation .transportation__scheduled-time {
            line-height: 30px;
          }

        .transportation__time-deviation {
          display: block;
          font-size: 17px;
          line-height: 15px;
        }

      .transportation__means {
        flex: 0 0 70px;
        font-family: 'roboto condensed', sans-serif;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
      }

        .transportation__number {
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 10px;
        }

          .transportation__item--bus .transportation__number {
            min-width: 35px;
            border: 2px solid currentcolor;
          }

          .transportation__means--oresundstag .transportation__number {
            color: #e83225;
          }

          .transportation__means--pagatag .transportation__number {
            color: #ab47bc;
          }

          .transportation__means--pagatag-express .transportation__number {
            padding-left: 2px;
            padding-right: 2px;
            background: #9c27b0;
            color: #000;
          }

          .transportation__means--city-bus .transportation__number {
            color: #66bb6a;
          }

          .transportation__means--regional-bus .transportation__number {
            color: #ffee58;
          }

          .transportation__number svg {
            vertical-align: -10px;
          }

      .transportation__towards {
        flex: 1 1 auto;
        padding-left: 8px;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .transportation__point {
        flex: 0 0 50px;
        margin-left: 5px;
        text-align: center;
        text-transform: uppercase;
        color: #999;
      }

        .transportation__item--canceled .transportation__point {
          color: #ef5350;
        }
