.forecast {
  overflow: auto;
}

  .forecast__list {
    position: relative;
  }

  .forecast__gutter {
    position: absolute;
    top: 25px;
    bottom: 25px;
    left: 117px;
    border-left: 2px dashed #999;
  }

  .forecast__label {
    margin: -20px -4px 0 -4px;
    padding: 0 4px;
    position: absolute;
    font-family: 'roboto condensed', sans-serif;
    line-height: 40px;
    background: #000;
  }

    .forecast__label--time {
      left: 20px;
      font-weight: 300;
      color: #999;
    }

    .forecast__label--sun {
      right: 20px;
      font-weight: 300;
    }

      .forecast__label--sun svg {
        margin-right: 5px;
        vertical-align: -5px;
      }

    .forecast__label--condition {
      left: 100px;
    }

      .forecast__label--condition svg {
        vertical-align: -5px;
      }

    .forecast__label--temperature {
      margin-left: 165px;
      border-radius: 10px;
      color: #fff;
    }

  .forecast__date-break {
    position: absolute;
    left: 122px;
    right: 20px;
  }

    .forecast__date-break::before {
      content: '';
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -1px;
      right: -1px;
      background: #000;
    }

    .forecast__date-break::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      border-top: 2px dashed #999;
    }

    .forecast__day-before,
    .forecast__day-after {
      margin-right: -2px;
      padding: 2px;
      position: absolute;
      right: 0;
      font-size: 17px;
      line-height: 15px;
      text-transform: uppercase;
      color: #999;
    }

    .forecast__day-before {
      margin-bottom: 3px;
      bottom: 100%;
    }

    .forecast__day-after {
      top: 4px;
    }

  .forecast__chart {
    position: absolute;
    left: 145px;
    top: 25px;
  }

    .forecast__chart-fill {
      fill: #222;
      stroke: none;
    }

    .forecast__chart-line {
      fill: none;
      stroke: #999;
      stroke-width: 2px;
      vector-effect: non-scaling-stroke;
    }
