.app {
  display: grid;
  flex: 1 1 auto;
  margin-left: auto;
  margin-right: auto;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  user-select: none;
}

  .app--dev {
    max-width: 600px;
    max-height: 1024px;
    outline: 5px dashed #080808;
  }

  .app__clock {
    grid-row: 1;
    border-bottom: 1px solid #666;
  }

  .app__main {
    position: relative;
    overflow: hidden;
    align-self: stretch;
    justify-self: stretch;
  }

    .app__section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      transition: left 250ms ease-in-out;
    }

  .app__sections {
    grid-row: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #666;
  }

    .app__section-switch {
      margin-top: -1px;
      padding: 16px 15px 20px;
      font-size: 35px;
      font-family: 'roboto condensed', sans-serif;
      font-weight: 300;
      text-align: left;

      border-top: 4px solid rgba(255, 255, 255, 0);
      color: #ccc;

      transition:
        color 250ms ease-in-out,
        border-color 250ms ease-in-out;
    }

      .app__section-switch--active {
        border-top-color: #fff;
        color: #fff;
      }

      .app__section-switch svg {
        vertical-align: -6px;
        opacity: 0.7;
        color: #ddd;
        transition: opacity 250ms ease-in-out, color 250ms ease-in-out;
      }

        .app__section-switch--active svg {
          opacity: 1;
        }

      .app__transportation-health--poor svg {
        color: #fff176;
      }

      .app__transpotration-health--severe svg {
        color: #ef5350;
      }

  .app__fullscreen-toggle {
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
  }
